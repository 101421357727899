import axios from "axios";

const state = {
  allExcursionsAndRoutes: [],
  types: [
    {
      type: "all",
      name: "Все",
      id: 1
    },
    {
      type: "route",
      name: "Маршруты",
      id: 2,
      subtypes: []
    },
    {
      type: "excursion",
      name: "Экскурсии",
      id: 3,
      subtypes: []
    }
  ],
}

const mutations = {
  SET_EXCURSIONS_AND_ROUTES: (state, payload) => {
    state.allExcursionsAndRoutes = payload;
  },

  SET_SUBTYPES_ROUTE: (state, payload) => {
    state.types[1].subtypes = payload;
  },
  SET_SUBTYPES_EXCURSION: (state, payload) => {
    state.types[2].subtypes = payload;
  },
}

const actions = {
  GET_EXCURSIONS_AND_ROUTES: async ({commit}) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trips?sort=created_at`);
    commit("SET_EXCURSIONS_AND_ROUTES", data.data);
  },

  GET_ROUTES: async ({commit}) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trips?type=route`);
    commit("SET_EXCURSIONS_AND_ROUTES", data.data);
  },

  GET_EXCURSIONS: async ({commit}) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trips?type=excursion`);
    commit("SET_EXCURSIONS_AND_ROUTES", data.data);
  },

  GET_FILTER_TYPE: async ({commit}, payload) => {
    if (payload.tagType === 'route') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trip_subtypes?type=route`);
      commit("SET_SUBTYPES_ROUTE", data.data);
    }
    if (payload.tagType === 'excursion') {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trip_subtypes?type=excursion`);
      commit("SET_SUBTYPES_EXCURSION", data.data);
    }
  },

  GET_ROUTES_AND_EXCURSIONS_SUBTYPES: async ({commit}, payload) => {
    const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/trips?type=${payload.type}&subtype_id=${payload.subtypeId}`);
    commit("SET_EXCURSIONS_AND_ROUTES", data.data)
  }
}
const getters = {
  EXCURSIONS_AND_ROUTES: (state) => {
    return state.allExcursionsAndRoutes;
  },

  TYPE: (state) => {
    return state.types;
  },
}

export default {
  state,
  mutations,
  actions,
  getters
}