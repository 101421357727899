<template>
  <div class="bottombar-main" :class="{'transparent':isTransparent,'shade':isShaded}">
    <div v-if="!isTransport" class="buttons" :class="{'collapsed':isCollapsed}">
      <div class="button-back" @click="goBack()">
        <div class="icon">
          <back-icon />
        </div>
        <div class="text">
          <span>Вернуться</span>
        </div>
      </div>

<!--      <div v-if="isTravel" class="button-map" @click="goToTransport">-->
<!--        <div class="icon">-->
<!--          <map-icon />-->
<!--        </div>-->
<!--        <div class="text">-->
<!--          <span>Городской транспорт</span>-->
<!--        </div>-->
<!--      </div>-->

      <div class="button-help"
        @click="goSos"
      >
        <div class="icon">
          <help-icon />
        </div>
        <div class="text">
          <span>Помощь</span>
        </div>
      </div>

      <!-- <div class="button-sos" @click="goToSosScreen()">
        <div class="icon">
          <sos-icon />
        </div>
        <div class="text">
          <span>SOS</span>
        </div>
      </div> -->

      <div class="button-home" :class="{ 'without-transport': !isTravel }" @click="goToMainScreen()">
        <div class="icon">
          <home-icon />
        </div>
      </div>
    </div>
    <div
      v-else
      class="buttons"
      :class="{'collapsed': isCollapsed}"
    >
      <div class="button-city-guide">
        <div class="icon">
          <city-guide />
        </div>
        <div
          @click="goToMainScreen"
          class="text"
        >
          Городской путеводитель
        </div>
      </div>

      <div class="button-taxi">
        <div class="icon">
          <taxi-icon />
        </div>
      </div>

      <div class="button-help">
        <div class="icon">
          <help-icon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapIcon from "@/components/Icons/Map";
import SosIcon from "@/components/Icons/Sos";
import BackIcon from "@/components/Icons/ArrowBack";
import HomeIcon from "@/components/Icons/Home";
import HelpIcon from "@/components/Icons/Help";
import CityGuide from "@/components/Icons/CityGuide";
import TaxiIcon from "@/components/Icons/Taxi";

export default {
  components: {
    MapIcon,
    SosIcon,
    BackIcon,
    HomeIcon,
    HelpIcon,
    CityGuide,
    TaxiIcon
  },
  computed: {
    isTransparent() {
      if (this.$route.path == "/") return true;
      return false;
    },
    isCollapsed() {
      if (this.$route.path == "/" || this.$route.path == "/home") return false;
      return true;
    },
    isTransport() {
      return this.$route.matched.some(({ name }) => name === 'transport');
    },
    isTravel() {
      return this.$route.matched.some(({ path }) => !path.startsWith('/transport'));
    },
    isShaded() {
      return this.$route.name == "MapScreen" ||
        this.$route.name == "SleepScreen" ||
        this.$route.name == "StoryScreen" ||
        this.$route.name == "SosScreen" ||
        this.$route.name == "PiligrimScreen"
        ? false
        : true;
    }
  },
  methods: {
    closePopups() {
      if (document.querySelector(".map-popup"))
        document.querySelector(".map-popup").classList.remove("show");
      this.$store.dispatch("setSelectedObject", null);
    },
    goBack() {
      if (document.querySelector(".map-popup") && document.querySelector(".map-popup").classList.contains("full")) {
        this.closePopups();
      } else {
        if (this.$route.matched.some(({ path }) => path.startsWith('/transport/'))) {
          this.$router.push({ path: '/transport'});
        } else {
          this.$router.push({ path: '/home' });
        }
        this.closePopups();
      }

    },
    goToMainScreen() {
      this.closePopups();
      this.$router.push({ path: "/home" });
    },
    goToSosScreen() {
      this.closePopups();
      this.$router.push({ path: "/sos" });
    },
    goToMap() {
      this.closePopups();
      this.$store.dispatch("setSelectedObject", null);
      if (this.$route.path !== "/map/0") this.$router.push({ path: "/map/0" });
    },
    goToTransport() {
      this.closePopups();
      this.$router.push({ path: "/transport" })
    },
    goSos() {
      this.closePopups();
      this.$router.push({ path: "/guide" });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.bottombar-main {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 168px;
  z-index: 401;
  background: $color_grey_2;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  &.transparent {
    background: transparent;
  }

  &::after {
    // border
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: rgba(#fff, 0.16);
  }

  &.shade::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 168px;
    bottom: 56px;
    z-index: -1;
    // z-index: 100;
    background-image: linear-gradient(
      to bottom,
      rgba($color_grey_2, 0),
      $color_grey_2 50%
    );
    // background: red;
  }

  .buttons {
    position: relative;
    padding: 28px 0px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    .icon {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // ---------------
    // button-map

    .button-map {
      position: absolute;
      width: 664px;
      height: 112px;
      left: 32px;
      top: 28px;
      border-radius: 55px;
      transition-delay: 0s;
      overflow: hidden;

      .icon {
        position: absolute;
        background: $grad_yellow_grey;
        transition-delay: 0.3s;
        z-index: 2;
      }

      .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        overflow: hidden;
        padding: 32px 82px 32px 148px;
        transition: all 0.3s ease-in;

        span {
          opacity: 1;
          position: relative;
          white-space: nowrap;
          font-size: 44px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.09;
          letter-spacing: -0.5px;
          transition: all 0.5s ease-in;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 112px;
          margin: 0;
          margin-top: 0;
          width: 100%;
          background-color: rgba(#fff, 0.2);
          border-radius: 55px;
          z-index: 1;
          transition: all 0.2s ease-in;
        }
      }
    }

    &.collapsed {
      .button-map {
        width: 112px;
        left: 800px;
        transition: all 0.2s ease-in;
        overflow: hidden;

        .text {
          overflow: hidden;
          width: 0;
          margin: 0;

          span {
            opacity: 0;
            transition: 0s;
          }
          &:before {
            width: 0%;
            transition: all 0.2s ease-in;
          }
        }
      }
    }

    // ---------------
    // button-taxi

    // ---------------
    // button-help
    .button-help {
      width: 324px;
      height: 112px;
      position: absolute;
      right: 32px;
      top: 28px;
      border-radius: 55px;
      background: $grad_red;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in;

      .icon {
        width: 62px;
        height: 62px;
        margin-right: 8px;
        opacity: 1;
        transition: all 0.2s ease-in;
      }

      .text {
        span {
          font-size: 44px;
          font-weight: normal;
          transition: all 0.5s ease-in;
        }
      }
    }


    .button-sos {
      width: 272px;
      height: 112px;
      position: absolute;
      right: 32px;
      top: 28px;
      border-radius: 55px;
      background: $grad_red;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in;

      .icon {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        opacity: 1;
        transition: all 0.2s ease-in;
      }

      .text {
        span {
          font-family: $OpenSans;
          font-size: 46px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.39;
          letter-spacing: -0.6px;
          opacity: 1;
          transition: all 0.5s ease-in;
        }
      }
    }

    &.collapsed {
      .button-help {
        width: 112px;
        transition: all 0.2s ease-in;
        overflow: hidden;

        .icon {
          margin: 0;
          transition: 0s;
        }

        .text {
          display: none;
        }
      }
    }

    // ---------------
    // button-back

    .button-back {
      position: absolute;
      left: 32px;
      top: 28px;
      width: 415px;
      height: 112px;
      border-radius: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grad_grey;
      opacity: 0;
      // z-index: 5;

      .icon {
        width: 64px;
        height: 64px;
        margin-right: 16px;
      }

      .text {
        font-family: $ObjectSans;
        font-size: 44px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: -0.5px;
      }
    }

    &.collapsed {
      .button-back {
        opacity: 1;
        transition: all 0.4s ease-in;
      }
    }

    // ---------------
    // button-home

    .button-home {
      position: absolute;
      left: 720px;
      top: 84px;

      .icon,
      svg {
        opacity: 0;
        width: 0px;
        height: 0px;
        background: #937abf;
      }
    }

    &.collapsed {
      .button-home {
        position: absolute;
        left: 800px;
        top: 28px;
        transition: all 0.4s ease-in;

        .icon {
          opacity: 1;
          width: 112px;
          height: 112px;
          background: #937abf;
          transition: all 0.4s ease-in;

          svg {
            width: 72px;
            height: 72px;
            opacity: 1;
            transition: all 0.4s ease-in;
          }
        }

        &.without-transport {
          left: 800px;
        }
      }
    }

    .button-city-guide {
      position: absolute;
      display: flex;
      align-items: center;
      width: 743px;
      margin-left: 32px;
      background: linear-gradient(180deg, rgba(75, 75, 75, 0.35) 0.65%, rgba(36, 36, 35, 0.65) 100%), #4B4B4B;
      border-radius: 56px;

      .icon {
        padding: 20px;
        border-radius: 56px;
        background-color: #0B83FF;
      }

      .text {
        padding: 32px 42px 32px 26px;
        font-size: 44px;
        font-weight: 400;
        line-height: 48px;
        color: #fff;
      }
    }

    .button-taxi {
      position: absolute;
      right: 167px;

      .icon {
        padding: 43px 24px;
        background: #F2C94C;
        border-radius: 56px;
      }
    }
  }
}
</style>
