<template>
  <div id="app">
    <top-bar
      :old-style="true"
      @show-route="showRoute"
    />
    <router-view
      :route="route"
    />
    <bottom-bar />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import TopBar from "@/components/TopBar";
import BottomBar from "@/components/BottomBar";

import {setSocketRoutes} from "@/api/socket";

export default {
  components: { TopBar, BottomBar },
  data() {
    return {
      route: null,
    }
  },
  computed: {
    ...mapGetters([
      "stopId"
    ]),

    stopId() {
      console.log(this.$route.query.stop_id, 'stop')
      return this.$route.query.stop_id
    }
  },
  beforeCreate() {
    this.$store.dispatch("fetchPanel", {
      panelId: this.$route.query.panel_id,
      stopId: this.$route.query.stop_id,
    });
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    // loadYmap();
    window.idleTimer = null;
    window.idleWait = process.env.NODE_ENV == "development" ? 1000000 : 45000;

    const listener = () => {
      clearTimeout(window.idleTimer);

      window.idleTimer = setTimeout(() => {
        if (this.$route.name == "StoryScreen") listener();
        else this.goToStartScreen();
      }, window.idleWait);
    };

    listener();
    document.querySelector("body").addEventListener("mousemove", listener); // its realy needed?
    document.querySelector("body").addEventListener("touchstart", listener);
    document.querySelector("body").addEventListener("keydown", listener);

    this.GET_TRANSPORT();
    this.GET_STOP(localStorage.getItem('stopId'));
  },

  methods: {
    ...mapActions(["GET_TRANSPORT", "GET_STOP"]),

    goToStartScreen() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },

    showRoute(item) {
      this.route = item;
    }
  },

  watch: {
    stopId: function(newVal) {
      if (newVal !== undefined) {
        localStorage.setItem('stopId', newVal)
      }

      this.GET_STOP(localStorage.getItem('stopId'));
      setSocketRoutes(localStorage.getItem('stopId'));
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
.leaflet-bottom {
  display: none;
}
</style>
