import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import socket from "@/api/socket.js";

// socket
// Vue.use(socket)

// leaflet-js
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from 'vue2-leaflet';
import LMovingMarker from 'vue2-leaflet-movingmarker';
import VueYandexMetrika from 'vue-yandex-metrika'

import 'leaflet/dist/leaflet.css';

// решение проблемы пропущенных иконок
// взято с офф документации vue2-leaflet
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-polyline', LPolyline);
Vue.component('l-moving-marker', LMovingMarker);
Vue.use(VueYandexMetrika, {
  id: 73100860,
  //router: true,
  env: 'production',
  options: {
    webvisor: true
  }
})

// global components
import HtmlParser from "@/components/Wrappers/HtmlParser";
Vue.component("txt", HtmlParser);

//swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper /* { default global options } */);

//moment js
const moment = require("moment");
require("moment/locale/ru");
Vue.use(require("vue-moment"), {
  moment
});

// ymap
// import YmapPlugin from "@/components/vue-yandex-maps";
import YmapPlugin from "vue-yandex-maps";
Vue.use(YmapPlugin, {
  apiKey: "",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1"
});

// api methods
import API from "@/api/api";
window.api = new API();

// config
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
